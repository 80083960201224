
import qs from 'qs'

const fields = [
]

export const queryNewsEntryThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: options?.sort || ['date_posted:desc',`${sortKey}:asc`],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 4,
      },
      fields,
      populate: {
        ...queryFragmentCollectionTypeDefault,
        ...queryFragmentBlocks,
        cover_image: true,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `news-entries?${query}`
}
