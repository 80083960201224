<template>
  <I18nLinkWithFallback
    name="news-slug"
    :localizedData="localizedData"
    :class="$style.root"
    :data-has-image="!!localizedData?.cover_image?.data?.attributes ? '' : null"
  >
    <div :class="$style.info">
      <CoreDateTimeRangeComponent
        :dateStart="localizedData?.date_posted"
        :class="$style.date"
      />
      <div>
        {{ title }}
      </div>
    </div>

    <CoreImageComponent
      v-if="localizedData?.cover_image?.data?.attributes"
      :image="localizedData?.cover_image?.data?.attributes"
      :class="$style.image"
    />
  </I18nLinkWithFallback>
</template>

<script setup>
const props = defineProps({
  data: Object
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(attributes?.value)
})

const title = computed(() => baseTruncateString(i18nGetLocalizedTitle(localizedData?.value), 8))


</script>

<style module lang="scss">
.root {
  composes: reset-link font-size-medium from global;
  display: block;
}

.root[data-has-image] {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--unit--horizontal);
  align-items: start;

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
    gap: var(--unit--vertical);
    text-align: center;
  }
}

.info:hover,
.info:global(.router-link-exact-active) {
  color: var(--color--yellow);
}

.date {
  color: var(--color--secondary);
}

.image {
  --base--image--aspect-ratio: 9 / 6;
  --base--image--object-fit: cover;

  transition: filter var(--transition--default);
  margin-top: calc(var(--lh) - var(--fs) - var(--stroke--default));
}

.root:has(.info:hover) .image {
  filter: invert(100%) grayscale(100%);
}
</style>
